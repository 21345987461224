@import url('https://fonts.googleapis.com/css?' );
@import url('https://fonts.googleapis.com/css?family=Exo:400,700');

main{
    font-family: 'Exo', sans-serif;
}

.bg {
  animation:slide 3s ease-in-out infinite alternate;
  background-image: linear-gradient(-20deg, red 50%, #000 50%);
  bottom:0;
  left:-100%;
  opacity:.5;
  position:fixed;
  right:-50%;
  top:0;
  z-index:-1;
}


.bg2 {
  animation-direction:alternate-reverse;
  animation-duration:4s;
}

.bg3 {
  animation-duration:5s;
}

.content {
  background-color:rgba(255,255,255,.8);
  border-radius:.25em;
  box-shadow:0 0 .25em rgba(0,0,0,.25);
  box-sizing:border-box;
  left:50%;
  padding:10vmin;
  position:fixed;
  text-align:center;
  top:50%;
  transform:translate(-50%, -50%);
}

h1 {
  font-family:monospace;
}

@keyframes slide {
  0% {
    transform:translateX(-25%);
  }
  100% {
    transform:translateX(25%);
  }
}




main{
	animation: CouleurB 4s linear infinite;
	-webkit-animation: CouleurB 4s linear infinite;
}

@keyframes CouleurB{
	0%{
		background-color: #071833;
	}

	12.5%{
		background-color: #1c0733;
	}

	25%{
		background-color: #21030c;
	}

	37.5%{
		background-color: #142103;
	}

	50%{
		background-color: #03211d;
	}

	63.5%{
		background-color: #d1af17;
	}

	75%{
		background-color: #4777a8;
	}

	86.5%{
		background-color: #fcf5f5;
	}

	100%{
		background-color: #0e7575;
	}

}

@-webkit-keyframes CouleurB{
	0%{
		background-color: #071833;
	}

	12.5%{
		background-color: #1c0733;
	}

	25%{
		background-color: #21030c;
	}

	37.5%{
		background-color: #142103;
	}

	50%{
		background-color: #03211d;
	}

	63.5%{
		background-color: #d1af17;
	}

	75%{
		background-color: #4777a8;
	}

	86.5%{
		background-color: #fcf5f5;
	}

	100%{
		background-color: #0e7575;
	}
}

@media screen and (max-width: 600px) {
  #mobile-menu-2 {
    visibility: hidden;
 
    display: none;
  }
}