@import url('https://fonts.googleapis.com/css?' );
@import url('https://fonts.googleapis.com/css?family=Exo:400,700');
@import url('https://fonts.googleapis.com/css2?family=Alkatra:wght@500;700&display=swap'); 
@import url('https://cdn.tailwindcss.com');


*{
    margin: 0px;
    padding: 2px;
}

body{
    font-family: 'Exo', sans-serif;
}


.context {
    width: 100%;
    position: absolute;
    top:50vh;
    
}

.context h1{
    text-align: center;
    color: #fff;
    font-size: 50px;
}


.area{
    background: #f1f1f5;  
     
}

.circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;
    
}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}


.mybg{
  background-color:#fff;
  background-image: url("D:\projetcinef\frontcertificat\public\images\cbackground.jpg");
  /* border: 8px; */
  /* border-color: #034e10;   */
  /* border-style: solid; */
  /* border-radius: 10px; */
  padding: 10px;
  margin: 10px;
  height:287mm ;
  /* box-shadow: 5px 5px 5px #034e10; */

}
.mobilebg{
 background-color:#fff;
  background-image: url("D:\projetcinef\frontcertificat\public\images\cbackground.jpg");
}



@keyframes animate {

    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}

body {
  background: rgb(204,204,204); 
}
page {
  background: white;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
}
page[size="A4"] {  
  width: 21cm;
  height: 29.7cm; 
}
page[size="A4"][layout="landscape"] {
  width: 29.7cm;
  height: 21cm;  
}
page[size="A3"] {
  width: 29.7cm;
  height: 42cm;
}
page[size="A3"][layout="landscape"] {
  width: 42cm;
  height: 29.7cm;  
}
page[size="A5"] {
  width: 14.8cm;
  height: 21cm;
}
page[size="A5"][layout="landscape"] {
  width: 21cm;
  height: 14.8cm;  
}
@media print {
  body, page {
    margin: 0;
    box-shadow: 0;    
    
  }
}
 
  @media only screen and (max-width: 600px) {
 .mobilepage{
    width:297mm  ;
    height:192mm ;
  }
}


.myname{

  font-family: Alkatra;
  text-align: center;
  
}

.theme{
font-size:28px;
font-size-adjust:0.6;  
height: auto;
margin-top: -45px;
text-justify: center;
justify-content: center;
justify-items: center;
/* font-family: open-sans; */

}

/* .mag{
  padding-top: 50px;
} */